import styles from "./LinkBox.module.scss"
import { DynamicHeadline } from "../DynamicHeadline"
import Link from "next/link"
import Section from "../Section"

const LinkBox = ({ data = {} }) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    links,
  } = data

  return links && links.length ? (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={styles.section}
      noPadding={true}
      container={false}
    >
      <div className={styles.sectionInner}>
        <div className={styles.sectionContent}>
          <DynamicHeadline
            headlineType={headlineType.value}
            headlineAppearance={headlineAppearance.value}
          >
            {dynamicHeadline}
          </DynamicHeadline>

          <ul className={styles.links}>
            {links.map((link, index) => (
              <li key={index} className={styles.link}>
                <div className={styles.box}>
                  <span className={styles.boxLabel}>{index + 1}.</span>
                </div>
                <div className={styles.label}>
                  <Link href={link.uri} passHref>
                    <a>{link.title}</a>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  ) : null
}

export default LinkBox
