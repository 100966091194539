/** @type {import('tailwindcss').Config} */

const plugin = require("tailwindcss/plugin")

module.exports = {
  content: ["./src/**/*.{js,jsx,html,ts,tsx}"],
  theme: {
    extend: {
      screens: {
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        "2xl": "1440px",
        "3xl": "1720px",
      },
      container: {
        padding: {
          DEFAULT: "1rem",
          sm: "0.875rem",
          md: "2rem",
          lg: "2rem",
          xl: "2.625rem",
          "2xl": "3.25rem",
          "3xl": "4rem",
        },
        center: true,
        grid: {
          sm: {
            size: "40px", // 2.5rem
            gap: "0.75rem",
          },
          md: {
            size: "44px", // 2.75rem
            gap: "1rem",
          },
          lg: {
            size: "58px", // 3.625rem
            gap: "1.5rem",
          },
          xl: {
            size: "74px", // 4.625rem
            gap: "1.75rem",
          },
          "2xl": {
            size: "82px", // 5.125rem
            gap: "2rem",
          },
          "3xl": {
            size: "96px", // 6rem
            gap: "2.5rem",
          },
        },
      },

      colors: {
        vollprimary: {
          name: "magenta",
          value: "#F50F55",
        },
        vollsecondary: {
          name: "blue",
          value: "#3845DC",
        },
        vollblue: {
          50: "#c5c7db",
          100: "#afb3db",
          200: "#9a9fdb",
          300: "#848bdb",
          400: "#6e77db",
          500: "#5863db",
          600: "#4653db",
          700: "#3845DC", // base
          800: "#2130db",
          900: "#0b1cdb",
        },
        vollmagenta: {
          50: "#ffccde",
          100: "#ffb3cd",
          200: "#ff99bd",
          300: "#ff80ac",
          400: "#ff669c",
          500: "#ff4d8b",
          600: "#ff337a",
          700: "#F50F55", // base
          800: "#ff0f63",
          900: "#ff0059",
        },
        vollgreen: {
          50: "#c2f2e0",
          100: "#aaf2d6",
          200: "#91f2cd",
          300: "#79f2c4",
          400: "#61f2bb",
          500: "#49f2b1",
          600: "#30f2a8",
          700: "#26F2A4", // base
          800: "#18f29f",
          900: "#0cf29a",
        },
        vollorange: {
          50: "#f2cfc2",
          100: "#f2bdaa",
          200: "#f2ab91",
          300: "#f29979",
          400: "#f28861",
          500: "#f27649",
          600: "#f26835",
          700: "#F25B26", // base
          800: "#f25218",
          900: "#f24100",
        },
        vollyellow: {
          50: "#ffffcc",
          100: "#ffffa6",
          200: "#ffff80",
          300: "#ffff66",
          400: "#ffff4d",
          500: "#ffff33",
          600: "#ffff1a",
          700: "#FFFF00", // base
          800: "#e6e600",
          900: "#cccc00",
        },
      },

      textColor: {
        primary: "#3490dc",
        secondary: "#ffed4a",
        danger: "#e3342f",

        "black-100": "#CECECE",
        // 'black-200': '',
        // 'black-300': '',
        // 'black-400': '',
        "black-500": "#6E6E6E",
        // 'black-600': '',
        // 'black-700': '',
        "black-800": "#464646",
        "black-900": "#2F2F2F",
        "black-1000": "#000000",
      },

      fontSize: {
        "3xs": ["0.5rem", "1.4"], // 8x
        "2xs": ["0.625rem", "1.4"], // 10x
        xs: [".75rem", "1.4"], // 12px
        sm: [".875rem", "1.2"], // 14px
        base: ["1rem", "1.6"], // 16px
        lg: ["1.125rem", "1.6"], // 18px
        xl: ["1.25rem", "1.6"], // 20px
        "2xl": ["1.375rem", "1.6"], // 22px
        "3xl": ["1.5rem", "1.2"], // 24px
        "4xl": ["1.625rem", "1.2"], // 26px
        "5xl": ["2rem", "1.2"], // 32px
        "6xl": ["2.5rem", "1.2"], // 40px
        "7xl": ["3rem", "1.2"], // 48px
        "8xl": ["3.5rem", "1.2"], // 56px
        "9xl": ["4rem", "1.2"], // 64px
        "10xl": ["4.5rem", "1.2"], // 72px
      },

      margin: {
        18: "4.5rem",
      },
      borderRadius: {
        "3xl": "1.25rem", //20px
        "4xl": "2.5rem", //40px
        "5xl": "3.75rem", //60px
        "6xl": "5rem", //80px
      },
      transitionTimingFunction: {
        "out-back": "cubic-bezier(0.34, 1.56, 0.64, 1)",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("@tailwindcss/forms"),

    plugin(function gridRasters({ addComponents, theme }) {
      const rasterSizes = ["12", "10", "8"]
      const grid = theme("container.grid")
      const containerSizes = Object.keys(grid)

      const rasters = rasterSizes.reduce((rasters, rasterSize, i) => {
        rasters[`.raster-${rasterSize}`] = containerSizes.reduce(
          (css, containerSize) => {
            const { size, gap } = grid[containerSize]
            css[`@screen ${containerSize}`] = {
              paddingLeft: `calc((${size} + ${gap}) * ${i} )`,
              paddingRight: `calc((${size} + ${gap}) * ${i} )`,
            }
            return css
          },
          {}
        )

        return rasters
      }, {})

      addComponents(rasters)
    }),

    plugin(function gridGaps({ addComponents, theme }) {
      const grid = theme("container.grid")
      const gridSizes = Object.entries(grid)

      const gaps = gridSizes.reduce((css, [key, { gap }]) => {
        css[`@screen ${key}`] = {
          gap,
        }
        return css
      }, {})

      addComponents({
        ".grid-gap": {
          gap: gridSizes[0][1].gap,
          ...gaps,
        },
      })
    }),

    plugin(function imgRadius({ addComponents, theme }) {
      const radius = theme("borderRadius")

      addComponents({
        ".img-radius": {
          borderRadius: radius["3xl"],
          overflow: "hidden",
          "@screen md": {
            borderRadius: radius["4xl"],
          },
        },
      })
    }),

    plugin(function hyphens({ addComponents, theme }) {
      addComponents({
        ".hyphens": {
          "-webkit-hyphens": "auto",
          "-ms-hyphens": "auto",
          hyphens: "auto",
        },
      })
    }),

    /**
     * Füge basis permutation Klassen hinzu für alle voll color Stärke 700
     * .permutation-${letter}-${colorName}
     */
    plugin(function ({ addBase, theme }) {
      const themeColors = theme("colors")
      const letters = ["v", "o", "l"]

      const css = Object.entries(themeColors)
        .filter(([key, value]) => {
          return key.startsWith("voll") && !key.startsWith("voll-")
        })
        .map(([name, colors]) => {
          return [name.substr(4), colors["700"]]
        })
        .reduce((css, [colorName, color]) => {
          letters.forEach((letter) => {
            css[`.permutation-${letter}-${colorName}`] = {
              backgroundImage: `url(/permutationen/permutation_${letter}_${colorName}.svg)`,
              backgroundRepeat: "repeat",
              "--permutation-size": "10rem",
              backgroundSize: "var(--permutation-size)",
            }
          })

          return css
        }, {})

      addBase(css)
    }),
  ],
}
