import * as Dialog from "@radix-ui/react-dialog"
import {
  ArrowRightIcon,
  Cross2Icon,
  ReloadIcon,
  ResetIcon,
} from "@radix-ui/react-icons"
import axios from "axios"
import cn from "classnames"
import Script from "next/script"
import { useState } from "react"
import Image from "../Image"
import LinkButton from "../ui/LinkButton"
import styles from "./ContactForm.module.scss"
import getConfig from "next/config"
import Section from "../Section"
import cx from "classnames"

const {
  publicRuntimeConfig: { NEXT_PUBLIC_RECAPTCHA_SITEKEY, UNIT_SITE },
} = getConfig()

/** ENABLE FOR LOCAL DEVELOPMENT TO SKIP RECAPTCHA AND GET A PRE-FILLED FORM **/
const DEBUG_MODE = false

const ContactForm = ({ data, content, parity }) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(DEBUG_MODE || false)

  const {
    contact_form_headline: headline,
    contact_form_text: text,
    contact_form_contact: contact,
    contact_form_phone: phone,
    contact_form_mail: mail,
  } = data

  const [contactPreference, setContactPreference] = useState("none")

  const formTestData = DEBUG_MODE
    ? {
        firstName: "Max",
        lastName: "Mustermann",
        email: "max.mustermann@voll.digital",
        company: "VOLL digital GmbH",
        phone: "",
        message: "Hallo Welt!",
        source: "Dies das",
        contactPreference: "phone",
      }
    : {}

  async function handleSubmit(e) {
    e.preventDefault()
    if (loading) {
      return
    }
    setLoading(true)

    if (!privacyChecked) {
      setError(true)
      setLoading(false)
      return
    }

    if (!DEBUG_MODE) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(NEXT_PUBLIC_RECAPTCHA_SITEKEY, { action: "submit" })
          .then((token) => {
            axios
              .post(
                "/api/contact",
                {
                  recaptchaResponse: token,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                if (response.status === 200) {
                  sendForm()
                } else {
                  setError(true)
                }
              })
          })
          .catch((error) => {
            setError(true)
            setLoading(false)
          })
      })
    } else {
      await sendForm()
    }
  }

  async function sendForm() {
    const contactForm = document.getElementById("contactForm")
    await axios
      .post(contactForm.action, new FormData(contactForm), {
        // does not work with set "content-type: multipart/form-data"
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
      .then(() => {
        //clear contactForm
        contactForm.reset()
        setLoading(false)
        setSuccess(true)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setError(true)
      })
  }

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${NEXT_PUBLIC_RECAPTCHA_SITEKEY}`}
        onLoad={() => {
          process.env.NODE_ENV === "development" &&
            console.log("recaptcha loaded")
        }}
      />

      <div className={styles.contactWrapper}>
        <div className={styles.introWrapper}>
          <h2 className={styles.title}>{headline}</h2>
          <div
            className={styles.copy}
            dangerouslySetInnerHTML={{ __html: text }}
          />

          <div className={styles.imageWrapper}>
            {contact?.avatar?.permalink ? (
              <div className={"flex-shrink-0 mb-8 w-full sm:w-2/5"}>
                <Image
                  className={styles.contactImage}
                  width={416}
                  height={561}
                  src={contact.avatar.permalink}
                  alt={contact.avatar.alt || "Kontaktbild"}
                  sizes="(max-width: 639px) 100vw, 192px"
                />
              </div>
            ) : null}
            <div className={cx("text-black ml-4 sm:ml-24 lg:ml-28 mb-8")}>
              {contact?.title ? (
                <div className={"font-bold"}>{contact.title}</div>
              ) : null}
              {contact?.position ? <div>{contact.position ?? null}</div> : null}
              {phone ? (
                <div>
                  <a className="" href={`tel:${phone}`}>
                    {phone}
                  </a>
                </div>
              ) : null}
            </div>
            <svg width="540" height="190" className={styles.vector}>
              <path
                d="M814 0.023555L153.23 0.023555C148.697 -0.17255 144.196 0.858969 140.212 3.00709C135.119 6.43446 130.433 10.4197 126.244 14.8867L12.0424 126.443C8.03279 130.475 4.89906 135.275 2.83336 140.549C0.767662 145.822 -0.186377 151.457 0.0300643 157.108L0.0300643 248L814 248V0.023555Z"
                fill="#FFFF00"
              />
            </svg>
          </div>
        </div>
        <div className={styles.formWrapper}>
          <form
            action={`${process.env.NEXT_PUBLIC_API_URL}/!/forms/contact/`}
            method="POST"
            className="grid grid-cols-1 mt-9 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            onSubmit={handleSubmit}
            id="contactForm"
          >
            <input
              type="hidden"
              name="unit"
              id="unit"
              defaultValue={UNIT_SITE}
            />
            <div>
              <label
                htmlFor="first-name"
                className="block font-medium text-gray-700"
              >
                Vorname *
              </label>
              <div className="mt-1">
                <input
                  required
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className={styles.inputFields}
                  defaultValue={formTestData.firstName ?? ""}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block font-medium text-gray-700"
              >
                Nachname *
              </label>
              <div className="mt-1">
                <input
                  required
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className={styles.inputFields}
                  defaultValue={formTestData.lastName ?? ""}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block font-medium text-gray-700"
              >
                Firma *
              </label>
              <div className="mt-1">
                <input
                  required
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className={styles.inputFields}
                  defaultValue={formTestData.company ?? ""}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block font-medium text-gray-700"
              >
                E-Mail *
              </label>
              <div className="mt-1">
                <input
                  required
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className={styles.inputFields}
                  defaultValue={formTestData.email ?? ""}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label
                  htmlFor="phone"
                  className="block font-medium text-gray-700"
                >
                  Telefon {contactPreference === "phone" ? "*" : ""}
                </label>
              </div>
              <div className="mt-1">
                <input
                  required={contactPreference === "phone"}
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  aria-describedby="phone-description"
                  className={styles.inputFields}
                  defaultValue={formTestData.phone ?? ""}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label
                  htmlFor="how-can-we-help"
                  className="block font-medium text-gray-700"
                >
                  Wie können wir euch helfen? *
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  required
                  id="how-can-we-help"
                  name="how-can-we-help"
                  aria-describedby="how-can-we-help-description"
                  rows={4}
                  className={styles.inputFields}
                  defaultValue={formTestData.message ?? ""}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label
                  htmlFor="contactPreference"
                  className="block font-medium text-gray-700"
                >
                  Bevorzugte Kontaktaufnahme
                </label>
              </div>
              <div className="mt-1 flex items-center flex-wrap lg:flex-nowrap gap-2 lg:gap-1">
                <label className="mr-4 w-full lg:w-auto">
                  <input
                    className={styles.radioButtons}
                    type="radio"
                    name="contactPreference"
                    value="email"
                    onChange={({ target }) =>
                      setContactPreference(target.value)
                    }
                    defaultChecked={contactPreference === "email"}
                  />{" "}
                  E-Mail
                </label>

                <label className="mr-4 w-full lg:w-auto">
                  <input
                    className={styles.radioButtons}
                    type="radio"
                    name="contactPreference"
                    value="phone"
                    onChange={({ target }) =>
                      setContactPreference(target.value)
                    }
                    defaultChecked={contactPreference === "phone"}
                  />{" "}
                  Telefon
                </label>

                <label className="w-full lg:w-auto">
                  <input
                    className={styles.radioButtons}
                    type="radio"
                    name="contactPreference"
                    value="none"
                    onChange={({ target }) =>
                      setContactPreference(target.value)
                    }
                    defaultChecked={contactPreference === "none"}
                  />{" "}
                  Keine Präferenz
                </label>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="how-did-you-hear-about-us"
                className="block font-medium text-gray-700"
              >
                Wie habt ihr von uns erfahren?
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="how-did-you-hear-about-us"
                  id="how-did-you-hear-about-us"
                  className={styles.inputFields}
                  defaultValue={formTestData.source ?? ""}
                />
              </div>
            </div>

            <div className="flex items-center sm:col-span-2">
              <input
                required
                id="datenschutz"
                name="datenschutz"
                defaultChecked={privacyChecked}
                onClick={({ target }) => setPrivacyChecked(target.checked)}
                type="checkbox"
                className={styles.radioButtons}
              />
              <label htmlFor="datenschutz" className="ml-3">
                <span className="block text-xs text-gray-700">
                  * Ich habe die{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/datenschutzerklaerung"
                  >
                    Datenschutzerklärung
                  </a>{" "}
                  gelesen und erkläre mich damit einverstanden. Wir nutzen eure
                  Daten nur für eure Anfragen.
                </span>
              </label>
            </div>

            <div className="text-right sm:col-span-2">
              <button
                className={cn("button", styles.submitButton, {
                  loading,
                })}
                aria-label="Absenden"
                disabled={loading}
              >
                {loading ? (
                  <>
                    Wird versendet{" "}
                    <ReloadIcon className={"animate-spin"} aria-hidden />
                  </>
                ) : (
                  <>
                    Absenden <ArrowRightIcon aria-hidden />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <Dialog.Root open={success} onOpenChange={(state) => setSuccess(state)}>
        <Dialog.Portal>
          <Dialog.DialogOverlay className={styles.successModalOverlay} />
          <Dialog.Content
            className={`${styles.modalContent} ${styles.successModalContent}`}
            role="alert"
          >
            <h5 className="mb-2 font-bold">Vielen Dank für Deine Anfrage!</h5>
            <p className="mb-2">
              Wir werden uns in Kürze mit Dir in Verbindung setzen.
            </p>
            <LinkButton
              data={{
                link_text: "Zur Startseite",
                link_url: "/",
                link_blank: false,
                show_arrow: true,
              }}
            />
            <Dialog.Close asChild>
              <div className={styles.iconButton}>
                <Cross2Icon />
              </div>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root open={error} onOpenChange={(state) => setError(state)}>
        <Dialog.Portal>
          <Dialog.DialogOverlay className={styles.errorModalOverlay} />
          <Dialog.Content
            className={`${styles.modalContent} ${styles.errorModalContent}`}
            role="alert"
          >
            <h5 className="mb-2 font-bold">
              {privacyChecked
                ? "Leider ist ein Fehler aufegetreten!"
                : "Du hast die Datenschutzerkärung noch nicht akzeptiert!"}
            </h5>
            <p className="mb-2">
              {privacyChecked && "Bitte versuche es später noch einmal."}
            </p>
            <button
              className={cn("button", styles.modalButton)}
              aria-label="Zurück zum Formular"
              onClick={() => setError(false)}
            >
              Zurück zum Formular <ResetIcon aria-hidden />
            </button>
            <Dialog.Close asChild>
              <div className={styles.iconButton}>
                <Cross2Icon />
              </div>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </Section>
  )
}

export default ContactForm
