import * as Accordion from "@radix-ui/react-accordion"
import { PlusIcon } from "@radix-ui/react-icons"
import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./Accordion.module.scss"
import { FAQPageJsonLd } from "next-seo"
import { stripHtml } from "../../utils/stripHtml"
import { stripHtmlSpaces } from "../../utils/strip-html-spaces"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const AccordionComponent = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    accordion,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    ankerslug,
  } = data

  const hoverColor = colors[parity].value

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.section}
      style={{ "--hover-color": hoverColor }}
    >
      <FAQPageJsonLd
        keyOverride={"accordion+" + Math.floor(Math.random() * 100000)}
        mainEntity={accordion.map((a) => ({
          questionName: a.dynamic_headline,
          acceptedAnswerText: stripHtml(a.accordionitem_content),
        }))}
      />

      <DynamicHeadline
        className={styles.headline}
        headlineType={headlineType.value}
        headlineAppearance={headlineAppearance.value}
      >
        {dynamicHeadline}
      </DynamicHeadline>

      <div className={styles.wrapper}>
        <Accordion.Root type="single" collapsible>
          {accordion.map((item, i) => {
            return (
              <Accordion.Item
                key={i}
                value={`item-${i + 1}`}
                className={styles.accordionItem}
              >
                <Accordion.Header asChild>
                  <Accordion.Trigger>
                    <div className={styles.itemHeadline}>
                      <div className={styles.icon}>
                        <PlusIcon aria-hidden />
                      </div>
                      <DynamicHeadline
                        headlineType={"h3"}
                        headlineAppearance={"h4"}
                      >
                        {item.dynamic_headline}
                      </DynamicHeadline>
                    </div>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content className={styles.content}>
                  <div className={styles.innerContent}>
                    {item.accordionitem_content &&
                      parse(stripHtmlSpaces(item.accordionitem_content))}
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            )
          })}
        </Accordion.Root>
      </div>
    </Section>
  )
}

export default AccordionComponent
