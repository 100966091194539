import cn from "classnames"

export const DynamicHeadline = ({
  headlineType = "h3",
  headlineAppearance = "h3",
  className,
  children,
}) => {
  const Hx = headlineType

  return <Hx className={cn(className, headlineAppearance)}>{children}</Hx>
}
