import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import Image from "../Image"
import styles from "./Partners.module.scss"
import LinkButton from "../ui/LinkButton"
import { stripHtmlSpaces } from "../../utils/strip-html-spaces"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

export const Partners = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    partners_text: text,
    partner_entries,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    ankerslug,
  } = data

  const settings = {
    accessibility: false,
    dots: false,
    draggable: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 7,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1720,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <>
      <Section
        sectionSpacing={{
          top: data?.section_padding_top?.value || null,
          bottom: data?.section_padding_bottom?.value || null,
        }}
        id={ankerslug}
        container={false}
      >
        <div className={styles.partnersWrapper}>
          <div className="mb-20 md:w-2/3">
            <DynamicHeadline
              headlineType={headlineType.value}
              headlineAppearance={headlineAppearance.value}
            >
              {dynamicHeadline}
            </DynamicHeadline>

            {text && (
              <div className="relative">
                {text &&
                  text.length > 0 &&
                  text.map((block, i) => {
                    switch (block.__typename) {
                      case "BardText":
                        return (
                          <div key={i}>
                            {parse(stripHtmlSpaces(block.text))}
                          </div>
                        )
                      case "Set_DynamicContent_PartnersText_KontaktButton":
                        return (
                          <LinkButton
                            key={i}
                            data={{
                              link_text: block.partners_text_link_text,
                              link_url: "/kontakt",
                            }}
                          />
                        )
                      default:
                        return
                    }
                  })}
              </div>
            )}
          </div>
        </div>
        <div className={styles.marquee}>
          {[0, 1].map((i) => (
            <ul
              key={i}
              className={styles.marquee__content}
              aria-hidden={i === 1}
            >
              {partner_entries &&
                partner_entries.length > 0 &&
                partner_entries.map((partner, i) => {
                  return (
                    partner.logo && (
                      <li key={i} className={styles.imageWrapper}>
                        <Image
                          className={styles.image}
                          src={partner.logo.permalink}
                          alt={partner.logo.alt}
                          width={partner.logo.width}
                          height={partner.logo.height}
                          layout="responsive"
                          placeholder="empty"
                        />
                      </li>
                    )
                  )
                })}
            </ul>
          ))}
        </div>
      </Section>
    </>
  )
}

export default Partners
