import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./TableComponent.module.scss"
import LinkButton from "../ui/LinkButton"
import { useEffect } from "react"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

export const TableComponent = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    table_text: tableText,
    table_image: tableImage,
    table,
    table_header_row: tableHeaderRow,
    table_header_column: tableHeaderColumn,
    ankerslug,
  } = data

  const primaryColorName = colors[parity].name

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.tableWrapper}
    >
      <div className={styles.headlineWrapper}>
        <div className={styles.headlineInner}>
          {dynamicHeadline && (
            <DynamicHeadline
              headlineType={headlineType.value}
              headlineAppearance={headlineAppearance.value}
              className={`${styles.headline}`}
            >
              {dynamicHeadline}
            </DynamicHeadline>
          )}
          <div className={styles.tableText}>
            {tableText &&
              tableText.length > 0 &&
              tableText.map((block, i) => {
                switch (block.__typename) {
                  case "BardText":
                    return <div key={i}>{parse(block.text)}</div>
                  case "Set_DynamicContent_TableText_KontaktButton":
                    return (
                      <LinkButton
                        key={i}
                        data={{
                          link_text: block.table_text_link_text,
                          link_url: "/kontakt",
                        }}
                      />
                    )
                  default:
                    return
                }
              })}
          </div>
        </div>
      </div>
      <div className={styles.tableOuter}>
        <table>
          {!!table?.length &&
            table.map((row, i) => (
              <Row
                key={i}
                row={row}
                isHeaderRow={tableHeaderRow && i === 0}
                isHeaderColumn={tableHeaderColumn && i > 0}
              />
            ))}
        </table>
      </div>
    </Section>
  )
}

const Row = ({ row, isHeaderRow, isHeaderColumn }) => {
  const { cells } = row

  if (!cells?.length) return null

  return isHeaderRow ? (
    <tr className={styles.headerRow}>
      {cells.map((cell, i) => (
        <th key={i}>{cell}</th>
      ))}
    </tr>
  ) : (
    <tr className={styles.bodyRow}>
      {cells.map((cell, i) =>
        isHeaderColumn && i === 0 ? (
          <th key={i}>{cell}</th>
        ) : (
          <td key={i}>
            {cell === "Y" ? (
              <div className={styles.iconWrapper}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="11" fill="#26F2A4" />
                  <path
                    d="M18 8L10.3333 16L6 11.8261"
                    stroke="black"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            ) : cell === "N" ? (
              <div className={styles.iconWrapper}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="11" fill="#F50F55" />
                  <path d="M17 7L7 17" stroke="white" strokeWidth="2" />
                  <path d="M7 7L17 17" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            ) : (
              cell
            )}
          </td>
        )
      )}
    </tr>
  )
}
