import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./Facts.module.scss"
import LinkButton from "../ui/LinkButton"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const Facts = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    facts_description: factsDescription,
    facts,
    ankerslug,
  } = data

  const color = colors[parity].value

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
    >
      <div className={styles.factsHeader}>
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
        >
          {dynamicHeadline}
        </DynamicHeadline>
        {factsDescription && (
          <div>
            {factsDescription &&
              factsDescription.length > 0 &&
              factsDescription.map((block, i) => {
                switch (block.__typename) {
                  case "BardText":
                    return <div key={i}>{parse(block.text)}</div>
                  case "Set_DynamicContent_FactsDescription_KontaktButton":
                    return (
                      <LinkButton
                        key={i}
                        data={{
                          link_text: block.facts_description_link_text,
                          link_url: "/kontakt",
                        }}
                      />
                    )
                  default:
                    return
                }
              })}
          </div>
        )}
      </div>
      <ul className={styles.factsList}>
        {facts.map((fact, i) => (
          <li key={i}>
            <div className={styles.factHeadlineWrapper}>
              <div
                className={styles.factHeadlineGraphic}
                style={{
                  backgroundColor: color,
                }}
              />
              <DynamicHeadline
                headlineType={fact.headline_type.value}
                headlineAppearance={fact.headline_appearance.value}
                className={styles.factHeadline}
              >
                {fact.dynamic_headline}
              </DynamicHeadline>
            </div>
            {fact.fact_description && (
              <div className={styles.text}>{parse(fact.fact_description)}</div>
            )}
          </li>
        ))}
      </ul>
    </Section>
  )
}

export default Facts
