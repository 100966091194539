import { default as classNames, default as cn } from "classnames"
import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import Image from "../Image"
import LinkButton from "../ui/LinkButton"
import styles from "./ImageGrid.module.scss"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const ImageGrid = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    image_grid_dynamic_headline: dynamicHeadline,
    image_grid_headline_type: headlineType,
    image_grid_headline_appearance: headlineAppearance,
    image_grid_images: images,
    image_grid_text: text,
    image_grid_link_text: linkText,
    image_grid_link_url: linkUrl,
    image_grid_link_blank: linkBlank,
    versatz,
    ankerslug,
  } = data

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.imageGrid}
    >
      <div className={styles.headlineWrapper}>
        <div className={styles.headlineContent}>
          <DynamicHeadline
            className={styles.headline}
            headlineType={headlineType.value}
            headlineAppearance={headlineAppearance.value}
          >
            {dynamicHeadline}
          </DynamicHeadline>
          {text && (
            <div>
              {text &&
                text.length > 0 &&
                text.map((block, i) => {
                  switch (block.__typename) {
                    case "BardText":
                      return <div key={i}>{parse(block.text)}</div>
                    case "Set_DynamicContent_ImageGridText_KontaktButton":
                      return (
                        <LinkButton
                          key={i}
                          data={{
                            link_text: block.image_grid_text_link_text,
                            link_url: "/kontakt",
                          }}
                        />
                      )
                    default:
                      return
                  }
                })}
            </div>
          )}
          {linkUrl && (
            <LinkButton
              data={{
                link_text: linkText,
                link_url: linkUrl,
                show_arrow: true,
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.images}>
        {images &&
          images.map((image, i) => {
            return (
              <div
                className={classNames(styles.imageWrapper, {
                  [styles.imageWrapperMargin]: versatz && i % 2 === 1,
                })}
                key={i}
              >
                <div className={styles.image}>
                  <Image
                    src={image.image.permalink}
                    alt={image.image?.alt}
                    layout="fill"
                    fill="objectFit"
                    objectFit="cover"
                    sizes="(max-width: 767px) 100vw, 50vw"
                  />
                </div>
                <div className={cn(styles.caption, "caption")}>
                  {image.caption}
                </div>
              </div>
            )
          })}
      </div>
    </Section>
  )
}

export default ImageGrid
