import Image from "../../Image"
import styles from "./Toolset.module.scss"
import { DynamicHeadline } from "../../DynamicHeadline"
import cn from "classnames"

export const Toolset = ({ set }) => {
  const {
    set_icons: icons,
    set_text: text,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
  } = set

  return (
    <div className={styles.setWrapper}>
      <DynamicHeadline
        headlineType={headlineType.value}
        headlineAppearance={headlineAppearance.value}
        className={styles.setHeadline}
      >
        {dynamicHeadline}
      </DynamicHeadline>
      <div className={styles.setIconsWrapper}>
        {icons &&
          icons.map((icon, i) => (
            <div key={i} className={styles.setIcon}>
              <Image
                layout="fill"
                alt={icon?.alt}
                src={icon.permalink}
                objectFit="cover"
                objectPosition="center center"
              />
            </div>
          ))}
      </div>
      <p className={styles.setText}>{text}</p>
    </div>
  )
}
