import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import BlockQuote from "./BlockQuote"
import styles from "./BlockQuotes.module.scss"
import Slider from "react-slick"
import { useRef } from "react"
import cn from "classnames"
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig
const BlockQuotes = ({
  data = {},
  content = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    quotes,
    dynamic_headline: dynamicHeadline,
    headline_appearance: headlineAppearance,
    headline_type: headlineType,
    quotes_fill_background: fillBg,
    ankerslug,
    quotes_type,
  } = data

  const primaryColor = colors[parity].value
  const secondaryColor = colors[parity ? 0 : 1].value

  const sliderRef = useRef(null)
  const sliderSettings = {
    centerMode: false,
    infinite: true,
    // fade: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
  }

  const quoteElements = quotes.map((quote, i) => {
    return (
      <BlockQuote
        key={i}
        data={quote}
        content={content}
        parity={parity}
        fillColor={primaryColor}
      />
    )
  })

  return (
    <Section
      id={ankerslug}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={styles.section}
    >
      {dynamicHeadline && (
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
          className={styles.headline}
        >
          {dynamicHeadline}
        </DynamicHeadline>
      )}
      <div className={styles.wrapper}>
        {quotes_type?.value === "slider" ? (
          <div>
            <Slider ref={sliderRef} {...sliderSettings}>
              {quoteElements}
            </Slider>
            <div className={styles.sliderControls}>
              <button
                className={cn("button", styles.prevButton, styles.highlight)}
                aria-label="Zum vorherigen Kundenzitat wechseln"
                onClick={() => sliderRef?.current?.slickPrev()}
              >
                <ArrowLeftIcon aria-hidden />
              </button>
              <button
                className={cn("button", styles.nextButton, styles.highlight)}
                aria-label="Zum nächsten Kundenzitat wechseln"
                onClick={() => sliderRef?.current?.slickNext()}
              >
                <ArrowRightIcon aria-hidden />
              </button>
            </div>
          </div>
        ) : (
          <ul className={styles.blockQuotes}>{quoteElements}</ul>
        )}
      </div>
    </Section>
  )
}

export default BlockQuotes
