import NextImage from "next/image"
import NextFutureImage from "next/future/image"

export const Image = (props) => {
  const src = props.src
  if (!src) return null

  const placeholder = props.placeholder || "blur"
  const isLegacyImage = !!props?.layout
  const isSvg = src.endsWith(".svg")
  const url = src

  return isLegacyImage ? (
    <NextImage
      {...props}
      src={url}
      placeholder={placeholder}
      blurDataURL={getImageUrl(isSvg, url, 10, 80)}
      loader={
        isSvg
          ? null
          : ({ src, width, quality }) =>
              getImageUrl(isSvg, src, width, quality || 80)
      }
      unoptimized={isSvg}
    />
  ) : (
    <NextFutureImage
      {...props}
      src={url}
      placeholder={placeholder}
      blurDataURL={getImageUrl(isSvg, url, 10, 80)}
      loader={
        isSvg
          ? null
          : ({ src, width, quality }) =>
              getImageUrl(isSvg, src, width, quality || 80)
      }
      unoptimized={isSvg}
    />
  )
}

const getImageUrl = (isSvg, url, width, quality = 80) => {
  if (isSvg) return url
  return `/_next/image?w=${width}&q=${quality}&url=${url}`
}

export default Image
