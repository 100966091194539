import cn from "classnames"
import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../../DynamicHeadline"
import Image from "../../Image"
import LinkButton from "../../ui/LinkButton"
import styles from "./Grid.module.scss"
import Section from "../../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig
const Grid = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    list,
    dynamic_headline: dynamicHeadline,
    headline_appearance: headlineAppearance,
    headline_type: headlineType,
    numbered_list: numberedList,
    versatz: offset,
    list_text: listText,
    ankerslug,
  } = data

  const primaryColor = colors[parity].value
  const secondaryColor = colors[parity ? 0 : 1].value

  const ListType = numberedList ? "ol" : "ul"

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.listWrapper}
    >
      {dynamicHeadline && (
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
          className={`${styles.listHeadline}`}
        >
          {dynamicHeadline}
        </DynamicHeadline>
      )}
      {listText && <div className={styles.listText}>{parse(listText)}</div>}
      <ListType className={cn(styles.list, { [styles.offset]: offset })}>
        {list.map((listItem, i) => {
          const { list_image: image } = listItem

          return (
            <li key={i}>
              {image && (
                <div className={styles.image}>
                  <Image
                    src={image.permalink}
                    width={image.width}
                    height={image.height}
                    alt={image?.alt}
                    sizes="(max-width: 767px) 100vw, 50vw"
                  />
                </div>
              )}
              <div
                className={styles.graphic}
                style={{
                  backgroundColor: primaryColor,
                }}
              >
                {numberedList && <span>{i + 1}</span>}
              </div>
              <div className={styles.textWrapper}>
                <DynamicHeadline
                  headlineType={listItem.headline_type.value}
                  headlineAppearance={listItem.headline_appearance.value}
                  className={`${styles.listItemHeadline}`}
                >
                  {listItem.dynamic_headline}
                </DynamicHeadline>
                <div className={styles.text}>
                  {listItem.listElementText && parse(listItem.listElementText)}
                  <LinkButton data={listItem} />
                </div>
              </div>
            </li>
          )
        })}
      </ListType>
    </Section>
  )
}

export default Grid
