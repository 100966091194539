import Link from "next/link"
import Masonry from "react-masonry-css"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./DesignPortfolio.module.scss"
import { PortfolioItem } from "./PortfolioItem"
import Section from "../Section"

export const DesignPortfolio = ({ data, showLink = false, maxItems }) => {
  // filter for maxItems
  data = maxItems ? data.filter((item, i) => i < maxItems) : data

  const portfolioItems = data.map(({ image, reference, headline }, index) => {
    if (headline) {
      return (
        <div className={styles.text} key={"portfolioHeadline"}>
          <DynamicHeadline headlineType={headline.type} className="h3">
            {headline.text}
          </DynamicHeadline>
        </div>
      )
    }
    return <PortfolioItem key={index} image={image} reference={reference} />
  })

  const breakpointColumnsObj = {
    default: 3,
    1280: 2,
    768: 1,
  }

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={styles.portfolioWrapper}
    >
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={styles.masonry}
        columnClassName={styles.masonry_column}
      >
        {portfolioItems}
      </Masonry>

      {showLink && (
        <Link href={"/referenzen"}>
          <a className={styles.referenceLink}>Zurück zu Referenzen</a>
        </Link>
      )}
    </Section>
  )
}

export const DesignPortfolioAdapter = ({ data, content, parity }) => {
  const referencesTree = data?.collections?.structure?.tree || []
  const references = referencesTree.map((treeItem) => treeItem.entry)
  const portfolioImages = references
    .map((reference) => {
      return reference.portfolio_images.map((image) => {
        return {
          reference,
          image,
        }
      })
    })
    .flat()

  const items = portfolioImages

  /*if (data.dynamic_headline) {
    items.splice(1, 0, {
      headline: {
        text: data.dynamic_headline,
        type: data.headline_type?.value,
      },
    })
  }*/

  return <DesignPortfolio data={items} />
}

export default DesignPortfolio
