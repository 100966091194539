import cn from "classnames"
import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import playSvg from "/public/play.svg"
import pauseSvg from "/public/pause.svg"

import Letter from "../ui/Letter"
import LinkButton from "../ui/LinkButton"
import styles from "./TextVideo.module.scss"
import { useEffect, useRef, useState } from "react"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const TextVideo = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
  noMargin,
}) => {
  const {
    textVideoText: text,
    video,
    video_orientation,
    letter: letterObj,
    link: url,
    link_text: linkText,
    new_tab: newTab,
    headline_teaser: headlineTeaser,
    dynamic_headline: headline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    text_video_fill_background: fillBg,
    cta_link: cta_Link,
    ankerslug,
  } = data

  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const playVideo = () => {
    if (!videoRef?.current) return

    if (!isPlaying) {
      setIsPlaying(true)
      videoRef.current.play()
    } else {
      setIsPlaying(false)
      videoRef.current.pause()
    }
  }

  if (!video?.is_video) return null

  const fillColor = colors[parity].value
  const bgColor = fillBg ? colors[parity === 0 ? 1 : 0].value : "transparent"

  const letter = letterObj?.value || "v"
  const hasVideo = !!video
  const hasLetter = letterObj && letter !== "none"
  const hasBg = fillBg && bgColor !== "transparent"
  const graphicRight = video_orientation.value === "right"

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={cn(styles.textVideoWrapper, {
        [styles.noMargin]: noMargin,
        [styles.filled]: hasBg,
        [styles.letterOnly]: hasLetter && !hasVideo,
        [styles.imageOnly]: !hasLetter && hasVideo,
      })}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div
        className={cn(styles.textImage, {
          [styles.flexReverse]: hasVideo && graphicRight,
        })}
      >
        <div className={styles.graphicWrapper}>
          {hasLetter && (
            <Letter
              type={letter}
              fill={fillColor}
              className={cn(styles.letter, {
                [styles.letterLeft]: !graphicRight,
                [styles.letterRight]: graphicRight,
                [styles.letterReverse]: hasLetter && graphicRight,
              })}
            />
          )}
          {hasVideo && (
            <div className={cn(styles.videoWrapper, "group")}>
              <video
                ref={videoRef}
                src={video.permalink}
                onEnded={() => setIsPlaying(false)}
              />
              <div
                className={cn(styles.playButtonWrapper, {
                  "hidden group-hover:flex": isPlaying,
                  "flex ": !isPlaying,
                })}
              >
                <button onClick={playVideo}>
                  {!isPlaying ? (
                    <>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        className="translate-x-0.5"
                        alt="Play icon"
                        src={playSvg.src}
                      />
                    </>
                  ) : (
                    <>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img alt="Pause icon" src={pauseSvg.src} />
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={styles.contentWrapper}>
          {headlineTeaser && (
            <p className={styles.headlineTeaser}>{headlineTeaser}</p>
          )}
          <DynamicHeadline
            headlineType={headlineType.value}
            headlineAppearance={headlineAppearance.value}
          >
            {headline}
          </DynamicHeadline>
          <div className={styles.text}>
            {text &&
              text.length > 0 &&
              text.map((block, i) => {
                switch (block.__typename) {
                  case "BardText":
                    return <div key={i}>{parse(block.text)}</div>
                  case "Set_DynamicContent_Text_KontaktButton":
                    return (
                      <LinkButton
                        key={i}
                        data={{
                          link_text: block.text_link_text,
                          link_url: "/kontakt",
                        }}
                      />
                    )
                  default:
                    return
                }
              })}
          </div>
          {url && (
            <LinkButton
              className={cn(
                styles.button,
                cta_Link ? `button--cta ${styles.ctaLink}` : `button`
              )}
              data={{
                link_url: url,
                link_text: linkText,
                link_blank: newTab,
              }}
            />
          )}
        </div>
      </div>
    </Section>
  )
}

export default TextVideo
