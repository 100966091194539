import parse from "html-react-parser"
import { DynamicHeadline } from "../../../DynamicHeadline"
import styles from "./ListItem.module.scss"
import LinkButton from "../../../ui/LinkButton"

export const ListItem = ({ item, color, order, type }) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    listElementText: text,
    link_text,
    link_url,
    link_blank,
  } = item

  return (
    <li className={styles.listItemWrapper}>
      <div className={styles.bg} style={{ background: color }} aria-hidden>
        {type && type === "ol" ? order : ""}
      </div>
      <div className={styles.titleWrapper}>
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
          className={styles.title}
        >
          {dynamicHeadline}
        </DynamicHeadline>
        <div className={styles.listItemText}>
          {text && parse(text)}
          {link_text && link_url && (
            <LinkButton
              data={{ link_text, link_url, link_blank, show_arrow: true }}
            />
          )}
        </div>
      </div>
    </li>
  )
}
