import { defaultColors, getColors } from "../../utils/color-combinations"
import AccordionComponent from "../Accordion"
import BlockQuotes from "../BlockQuotes"
import { DesignPortfolioAdapter } from "../DesignPortfolio"
import DividerHeadline from "../DividerHeadline"
import Facts from "../Facts"
import HomeHero from "../HomeHero"
import ImageGrid from "../ImageGrid"
import ImagesSection from "../ImagesSection"
import LinkList from "../LinkList"
import List from "../List"
import Partners from "../Partners"
import PriceSlider from "../PriceSlider"
import ReferencesSlider from "../ReferencesSlider"
import { TableComponent } from "../TableComponent"
import TeaserLinks from "../TeaserLinks"
import TextImage from "../TextImage"
import Tools from "../Tools"
import Wysiwyg from "../Wysiwyg"
import { useEffect } from "react"
import hljs from "highlight.js/lib/core"
import "highlight.js/styles/atom-one-light.css"
import TextVideo from "../TextVideo"
import AudioComponent from "../Audio"
import LatestEdit from "../LatestEdit"
import ContactForm from "../ContactForm"
import LinkBox from "../LinkBox"
import AuthorBox from "../AuthorBox"

// You have to define and register the specific languages here, because auto-detection doesn't work correctly.
const hljsLanguages = ["css", "scss", "javascript", "xml"]
hljs.registerLanguage("css", require("highlight.js/lib/languages/css"))
hljs.registerLanguage("scss", require("highlight.js/lib/languages/scss"))
hljs.registerLanguage(
  "javascript",
  require("highlight.js/lib/languages/javascript")
)
hljs.registerLanguage("xml", require("highlight.js/lib/languages/xml"))

hljs.configure({
  languages: hljsLanguages,
})

const DynamicContent = ({ content, references }) => {
  const singlePageContent = Array.isArray(content)
    ? content.length === 1
      ? content[0]
      : null
    : content

  // ContentTypes
  const contentTypes = {
    Set_DynamicContent_Wysiwyg: Wysiwyg,
    Set_DynamicContent_Quotes: BlockQuotes,
    Set_DynamicContent_Images: ImagesSection,
    Set_DynamicContent_List: List,
    Set_DynamicContent_Facts: Facts,
    Set_DynamicContent_DividerHeadline: DividerHeadline,
    Set_DynamicContent_TextImage: TextImage,
    Set_DynamicContent_Accordion: AccordionComponent,
    Set_DynamicContent_LinkList: LinkList,
    Set_DynamicContent_Tools: Tools,
    Set_DynamicContent_Partners: Partners,
    Set_DynamicContent_HomeHero: HomeHero,
    Set_DynamicContent_DesignPortfolio: DesignPortfolioAdapter,
    Set_DynamicContent_TableComponent: TableComponent,
    Set_DynamicContent_PriceSlider: PriceSlider,
    Set_DynamicContent_ImageGrid: ImageGrid,
    Set_DynamicContent_Referencesslider: ReferencesSlider,
    Set_DynamicContent_TeaserLinks: TeaserLinks,
    Set_DynamicContent_TextVideo: TextVideo,
    Set_DynamicContent_Audio: AudioComponent,
    Set_DynamicContent_LatestEdit: LatestEdit,
    Set_DynamicContent_ContactForm: ContactForm,
    Set_DynamicContent_Linkbox: LinkBox,
    Set_DynamicContent_Authorbox: AuthorBox,
  }

  const dynamicContent = singlePageContent?.dynamic_content || []
  let colorCount = ["V", "L"].includes(singlePageContent?.letter?.value)
    ? singlePageContent.title_image
      ? 0
      : 1
    : -1

  useEffect(() => {
    document.querySelectorAll("pre code").forEach((el) => {
      hljs.highlightElement(el)
    })
  }, [])

  return (
    <>
      {/* Dynamic singlePageContent sections */}
      {dynamicContent.map((item, i) => {
        const Component = contentTypes[item.__typename]

        if (!Component) return null

        // Ignore global margin when a TextImage component follow another TextImage with filled background
        let noMargin = false
        if (item.__typename === "Set_DynamicContent_TextImage") {
          const nextItem = dynamicContent[i + 1]
          const nextComponent = nextItem?.__typename
          const nextIsFilled = nextItem?.text_image_fill_background
          const isFilled = item?.text_image_fill_background
          if (
            nextComponent === "Set_DynamicContent_TextImage" &&
            isFilled &&
            nextIsFilled
          ) {
            noMargin = true
          }
        }

        const pageColors = getColors(singlePageContent)
        const itemColors = getColors(item)
        const colors = itemColors || pageColors || defaultColors

        // Ignore parities if dynamic content item has own colors
        if (!itemColors) colorCount++
        const parity = itemColors ? 0 : colorCount % 2

        const hasReferences = references?.length > 0
        const caseStudies = hasReferences ? references : null

        return (
          <Component
            key={i}
            data={item}
            content={singlePageContent}
            parity={parity}
            colors={colors}
            references={caseStudies}
            noMargin={noMargin}
          />
        )
      })}
    </>
  )
}

export default DynamicContent
