import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./TeaserLinks.module.scss"
import TeaserLinksItem from "./TeaserLinksItem"
import Section from "../Section"

const TeaserLinks = ({ data, colors, content }) => {
  const {
    dynamic_headline: headline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    teasers,
    ankerslug,
  } = data
  const color = colors && colors[0].value

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.teaserLinksSection}
    >
      {headline && (
        <DynamicHeadline
          className={styles.teaserHeadline}
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
        >
          {headline}
        </DynamicHeadline>
      )}
      <div className={styles.teaserWrapper}>
        {teasers &&
          teasers.map((teaser, index) => (
            <TeaserLinksItem teaser={teaser} key={index} color={color} />
          ))}
      </div>
    </Section>
  )
}

export default TeaserLinks
