import cx from "classnames"
import styles from "./Section.module.scss"
import { useEffect } from "react"

/*
sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
 */

export const Section = ({
  children = {},
  className = "",
  container = true,
  sectionSpacing = {
    top: null,
    bottom: null,
  },
  noPadding = false,
  ...rootProps
}) => {
  // console.log("Section", sectionSpacing)

  if (sectionSpacing.top === null) {
    sectionSpacing.top = "medium"
  }

  if (sectionSpacing.bottom === null) {
    sectionSpacing.bottom = "medium"
  }

  return (
    <section
      data-s-top={sectionSpacing.top}
      data-s-bottom={sectionSpacing.bottom}
      className={cx(
        styles.Section,
        {
          [styles.hasContainer]: container,
          [styles[`paddingTop-${sectionSpacing.top}`]]:
            !noPadding && sectionSpacing.top,
          [styles[`paddingBottom-${sectionSpacing.bottom}`]]:
            !noPadding && sectionSpacing.bottom,
        },
        className
      )}
      {...rootProps}
    >
      {children}
    </section>
  )
}

export default Section
