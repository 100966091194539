import Image from "../../Image"
import styles from "./PortfolioItem.module.scss"

export const PortfolioItem = ({ image, reference }) => {
  const { slug } = reference
  const url = `/referenzen/${slug}`

  return (
    <div className={styles.image}>
      {/* <Modal> */}
      {image && (
        <Image
          src={image.permalink}
          alt={image?.alt}
          width={image.width}
          height={image.height}
          layout="responsive"
        />
      )}
    </div>
  )
}
