import { twCustomConfig } from "./tw-config"

const {
  theme: {
    colors: {
      vollprimary: { name: defaultPrimaryColor },
      vollsecondary: { name: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const defaultColorCombination = `${defaultPrimaryColor}-${defaultSecondaryColor}`

const colorCombinations = (data = {}) => {
  const themeColors =
    data.theme_colors_combinations?.value ||
    `${defaultPrimaryColor}-${defaultSecondaryColor}`
  const colors = themeColors.split("-")

  return {
    primary: colors[0] || defaultPrimaryColor,
    secondary: colors[1] || defaultSecondaryColor,
  }
}

export const getColors = (data) => {
  const themeColors =
    typeof data === "string"
      ? data
      : data?.theme_colors_combinations?.value || defaultColorCombination
  if (!themeColors) return

  const colors = themeColors
    .split("-")
    .filter((color) => !!color)
    .map((color) => {
      return {
        name: color,
        value: twCustomConfig.theme.colors[`voll${color}`]["700"],
      }
    })

  if (!colors.length) return

  return colors
}

export const defaultColors = getColors()

export default colorCombinations
