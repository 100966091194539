import styles from "./Audio.module.scss"
import { DynamicHeadline } from "../DynamicHeadline"
import parse from "html-react-parser"
import Section from "../Section"

const AudioComponent = ({ data }) => {
  const {
    dynamic_headline: dynamicHeadline,
    audio_description: audioDescription,
    audio_asset: { is_audio: isAudio, permalink },
    headline_type: { value: headlineType },
    headline_appearance: { value: headlineAppearance },
    ankerslug,
  } = data

  if (!isAudio) return null

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.audioSection}
    >
      <DynamicHeadline
        className={styles.sectionHeadline}
        headlineType={headlineType}
        headlineAppearance={headlineAppearance}
      >
        {dynamicHeadline}
      </DynamicHeadline>

      {audioDescription && (
        <div className={styles.audioDescription}>{parse(audioDescription)}</div>
      )}
      <audio className={styles.audio} controls src={permalink}></audio>
    </Section>
  )
}

export default AudioComponent
