import Link from "next/link"
import styles from "./LinkListItem.module.scss"

export const LinkListItem = ({ item, color }) => {
  const { link: url, new_tab: newTab, linkText: text } = item

  return (
    <div className={styles.linkListItemWrapper}>
      <div
        className={styles.bg}
        style={{ backgroundColor: color }}
        aria-hidden
      />
      {url && (
        <Link href={url}>
          <a
            className={styles.link}
            target={newTab ? "_blank" : "_self"}
            rel="noreferrer noopener"
          >
            {text}
          </a>
        </Link>
      )}
    </div>
  )
}
