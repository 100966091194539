import cn from "classnames"
import Image from "../Image"
import styles from "./ImagesSection.module.scss"

import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import Section from "../Section"

const ImagesSection = ({ data }) => {
  const { full_width: fullWidth, images } = data
  const multpileImages = images.length > 1

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div
        className={cn(styles.wrapper, {
          [styles.full]: fullWidth,
          [styles.multiple]: multpileImages,
        })}
      >
        {images.map((image, i) => (
          <div className={styles.image} key={i}>
            <Zoom wrapStyle={{ display: "block" }}>
              <Image
                src={image.permalink}
                alt={image?.alt}
                height={image.height}
                width={image.width}
                sizes="(max-width: 1199px) 100vw, 1200px"
              />
            </Zoom>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default ImagesSection
