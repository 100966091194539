import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./DividerHeadline.module.scss"
import Section from "../Section"

const {
  theme: {
    colors: {
      vollprimary: { value: defaultPrimaryColor },
      vollsecondary: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig
const DividerHeadline = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    ankerslug,
  } = data

  const backgroundColor = colors[parity].value

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
    >
      <div className={styles.wrapper}>
        <DynamicHeadline
          className={styles.headline}
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
        >
          {dynamicHeadline}
        </DynamicHeadline>
        <div style={{ backgroundColor }} className={styles.square} />
      </div>
    </Section>
  )
}

export default DividerHeadline
