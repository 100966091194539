import cn from "classnames"
import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import Letter from "../ui/Letter"
import styles from "./HomeHero.module.scss"
import Section from "../Section"

const {
  theme: {
    colors: {
      // vollprimary: { value: defaultPrimaryColor },
      vollmagenta: { 700: magenta },
      vollsecondary: { value: defaultSecondaryColor },
      vollblue: { 700: blue },
      vollgreen: { 700: green },
    },
  },
} = twCustomConfig

export const HomeHero = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    hero_images: images,
    hero_linked_entries: entries,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    teaser_title: teaserTitle,
    teaser_text: teaserText,
    content_text: contentText,
  } = data

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={cn("home-hero", styles.homeWrapper)}
      container={false}
    >
      <div className="relative">
        <div className={styles.letterLWrapper}>
          <div className="relative mb-4">
            <Letter type="l" fill={green} className={styles.letterL} />
          </div>
          <div className="relative">
            <Letter type="l" fill={green} className={styles.letterL} />
          </div>
        </div>

        <div className={styles.letterOWrapper}>
          <div className="relative">
            <Letter type="o" fill={blue} />
          </div>
        </div>

        <div className={styles.letterVWrapper}>
          <div className="relative">
            <Letter type="v" fill={magenta} />
          </div>
        </div>

        {dynamicHeadline && (
          <div className={styles.headlineWrapper}>
            <h1 className="text-lg mb-2 md:text-3xl font-medium md:mb-0">
              {teaserTitle}
            </h1>
            <DynamicHeadline
              className="w-3/4 hero-text"
              headlineType={headlineType.value}
              headlineAppearance={headlineAppearance.value}
            >
              {dynamicHeadline}
            </DynamicHeadline>
            {teaserText && (
              <div className={cn(styles.text, "teaser")}>
                {parse(teaserText)}
              </div>
            )}
          </div>
        )}
      </div>
    </Section>
  )
}

export default HomeHero
