import moment from "moment"
import styles from "./LatestEdit.module.scss"
import Section from "../Section"

export const LatestEdit = ({ content, format = "D. MMMM YYYY", data = {} }) => {
  const timestamp = content.date
  const show_latest_edit = data.latest_edit

  return timestamp && show_latest_edit ? (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.content}>
        <p>Zuletzt aktualisiert:</p>
        <p>{moment(timestamp).format(format)}</p>
      </div>
    </Section>
  ) : null
}

export default LatestEdit
