import Link from "next/link"
import Image from "../../Image"
import parse from "html-react-parser"
import styles from "./TeaserLinksItem.module.scss"
import { DynamicHeadline } from "../../DynamicHeadline"
import cn from "classnames"
import LinkButton from "../../ui/LinkButton"

const TeaserLink = ({ url, children, className, openNewTab }) => {
  return url ? (
    <Link href={url}>
      <a className={className} target={openNewTab ? "_blank" : "_self"}>
        {children}
      </a>
    </Link>
  ) : (
    <div className={className}>{children}</div>
  )
}

const TeaserLinksItem = ({ teaser, color }) => {
  const {
    link_link: link,
    link_image: image,
    link_text: text,
    dynamic_headline: headline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    new_tab: newTab,
  } = teaser

  const teaserLinkWrapperCN = cn(styles.teaserLinkWrapper, {
    [styles.withImage]: image,
    [styles.withoutImage]: !image,
  })

  const bgCN = cn(styles.bg, {
    "-ml-8 -mt-24 sm:-mt-56 lg:-mt-24 xl:-mt-56": image,
    "-ml-8": !image,
  })

  return (
    <TeaserLink className={teaserLinkWrapperCN} url={link} openNewTab={newTab}>
      {image ? (
        <>
          <div className={styles.imageWrapper}>
            <Image
              src={image.permalink}
              alt={image.alt}
              className={styles.image}
              quality={100}
              sizes="(max-width: 1024px) 1920px, (max-width: 1280px) 828px, (max-width: 1720px) 768px, 1080px"
              fill
            />
          </div>
          <div className={styles.inner}>
            <div className={bgCN} style={{ background: color }} aria-hidden />
            <div className={styles.content}>
              {headline && (
                <DynamicHeadline
                  className="last:mb-0"
                  headlineType={headlineType.value}
                  headlineAppearance={headlineAppearance.value}
                >
                  {headline}
                </DynamicHeadline>
              )}
              {text && (
                <div className={styles.text}>
                  {text &&
                    text.length > 0 &&
                    text.map((block, i) => {
                      switch (block.__typename) {
                        case "BardText":
                          return <div key={i}>{parse(block.text)}</div>
                        case "Set_DynamicContent_Teasers_LinkText_KontaktButton":
                          return (
                            <LinkButton
                              key={i}
                              data={{
                                link_text: block.link_text_link_text,
                                link_url: "/kontakt",
                              }}
                            />
                          )
                        default:
                          return
                      }
                    })}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.inner}>
            <div className={bgCN} style={{ background: color }} aria-hidden />
            <div className="col-start-1 row-start-1">
              <DynamicHeadline
                className={styles.headline}
                headlineType={headlineType.value}
                headlineAppearance={headlineAppearance.value}
              >
                {headline}
              </DynamicHeadline>
              {text && (
                <div className={styles.text}>
                  {text &&
                    text.length > 0 &&
                    text.map((block, i) => {
                      switch (block.__typename) {
                        case "BardText":
                          return <div key={i}>{parse(block.text)}</div>
                        case "Set_DynamicContent_Teasers_LinkText_KontaktButton":
                          return (
                            <LinkButton
                              key={i}
                              data={{
                                link_text: block.link_text_link_text,
                                link_url: "/kontakt",
                              }}
                            />
                          )
                        default:
                          return
                      }
                    })}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </TeaserLink>
  )
}

export default TeaserLinksItem
