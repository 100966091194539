import styles from "./LetterImage.module.scss"
import { letters } from "./Definitions"

const LetterImage = ({
  type = "v",
  color = "green",
  image,
  focus = "center",
  className = "",
}) => {
  const letterId = type.toLowerCase()
  const letter = letters[letterId]

  const letterStyles = {
    backgroundColor: color,
    paddingTop: `${(letter.height / letter.width) * 100}%`,
    clipPath: `url(#letter-path-${letterId})`,
  }

  if (image) {
    letterStyles.backgroundImage = `url("${image}")`
  }
  if (focus) {
    letterStyles.backgroundPosition = focus
  }

  return (
    <div className={className}>
      <div className={styles.img} style={letterStyles} />
    </div>
  )
}

export default LetterImage
